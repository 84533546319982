import { EditIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  Link as RemixLink,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UseModalProps,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-day-picker/dist/style.css";
import {
  Form,
  Link,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDate, useToken } from "~/utils";
import http, { getBaseUrl } from "~/utils/http";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";

function SearchFields({
  initialStartDate,
  initialEndDate,
}: {
  initialStartDate: Date | null;
  initialEndDate: Date | null;
}) {
  const today = new Date();
  const location = useLocation();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);
  const [error, setError] = useState<string | null>(null);
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (startDate && endDate) {
      if (startDate > endDate) {
        setError("Start date should not be later than end date");
        return;
      }
    }
    setError(null);
    let params = new URLSearchParams(location.search);
    params.set(
      "start-date",
      startDate ? startDate.toLocaleDateString("en-CA") : "null"
    );
    params.set(
      "end-date",
      endDate ? endDate.toLocaleDateString("en-CA") : "null"
    );
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <Box py="10" minH={"100%"}>
      <Box as={Form} onSubmit={handleSubmit} mb={4}>
        <VStack spacing={5} px={8}>
          <FormControl>
            <FormLabel>Start Date (YYYY-MM-DD):</FormLabel>
            <DatePicker
              showIcon
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              maxDate={today}
            />
            <FormErrorMessage>Error</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>End Date (YYYY-MM-DD):</FormLabel>
            <DatePicker
              showIcon
              dateFormat="yyyy-MM-dd"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              maxDate={today}
            />
            <FormErrorMessage>Error</FormErrorMessage>
          </FormControl>
          <Button w="200px" colorScheme="blue" type="submit">
            Search
          </Button>
          {error && <Text color={"red.600"}>{error}</Text>}
        </VStack>
      </Box>
    </Box>
  );
}

export interface UserStat {
  id: number;
  alias?: string;
  avatar?: string;
  video_keys: string[];
}

function DashboardContent({
  startDate,
  endDate,
}: {
  startDate: Date | null;
  endDate: Date | null;
}) {
  const token = useToken();
  const [stats, setStats] = useState<UserStat[] | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserStat | null>(null);
  useEffect(() => {
    async function loadData() {
      let params = new URLSearchParams();
      params.set("pageIndex", "0");
      params.set("pageSize", "9999");
      if (startDate) {
        params.set("startDate", startDate.toLocaleDateString("en-CA"));
      }
      if (endDate) {
        params.set("endDate", endDate.toLocaleDateString("en-CA"));
      }
      setStats(null);
      let res = await http.get<{ users: UserStat[] | null }>(
        "/platform/users/stats",
        {
          params: params,
          headers: {
            Authorization: token,
          },
        }
      );
      setStats(res.data.users || []);
    }
    loadData();
  }, [startDate, endDate]);

  if (stats === null) {
    return (
      <Container textAlign={"center"} my={8}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Container>
    );
  }

  let params = new URLSearchParams();
  params.set("token", token || "");
  if (startDate) {
    params.set("startDate", startDate.toLocaleDateString("en-CA"));
  }
  if (endDate) {
    params.set("endDate", endDate.toLocaleDateString("en-CA"));
  }

  return (
    <Container maxW="5xl">
      <Heading textAlign={"center"} mt={8} mb={8} size="lg">
        User uploads from{" "}
        {startDate ? startDate.toLocaleDateString("en-CA") : "the beginning"} to{" "}
        {endDate ? endDate.toLocaleDateString("en-CA") : "today"}
      </Heading>
      <Box mb="8" textAlign={"center"}>
        <Button
          as={RemixLink}
          href={`${getBaseUrl()}/platform/users/stats/download?${params.toString()}`}
          colorScheme="blue"
        >
          Download as CSV
        </Button>
      </Box>
      <StatsTable
        stats={stats}
        onSelectUser={(user) => setSelectedUser(user)}
      />
      <UserEditModal
        user={selectedUser}
        onClose={() => {
          setSelectedUser(null);
        }}
        onUpdate={(user) => {
          let users = stats.map((stat) => {
            if (stat.id === user.id) {
              return user;
            } else {
              return stat;
            }
          });
          setStats(users);
          setSelectedUser(null);
        }}
      />
    </Container>
  );
}

function StatsTable({
  stats,
  onSelectUser,
}: {
  stats: UserStat[];
  onSelectUser: (user: UserStat) => void;
}) {
  const location = useLocation();
  const token = useToken();
  if (!stats.length) {
    return (
      <Text textAlign={"center"} fontSize={"lg"}>
        No record
      </Text>
    );
  }
  return (
    <TableContainer>
      <Table borderWidth={"thin"} variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th w={"60px"}></Th>
            <Th w={"120px"}>Avatar</Th>
            <Th>User</Th>
            <Th isNumeric>Uploaded Count</Th>
          </Tr>
        </Thead>
        <Tbody>
          {stats.map((user) => {
            return (
              <Tr key={user.id}>
                <Td>
                  <Button variant={"link"} onClick={() => onSelectUser(user)}>
                    <EditIcon />
                  </Button>
                </Td>
                <Td>
                  <Avatar
                    borderRadius={"10%"}
                    src={user.avatar}
                    name={user.alias}
                    size={"lg"}
                  />
                </Td>
                <Td>
                  <HStack>
                    <Text>{user.alias || `<id:${user.id}>`}</Text>{" "}
                  </HStack>
                </Td>

                <Td isNumeric>
                  <Button
                    as={Link}
                    variant={"link"}
                    to={`/users/${user.id}${location.search}&token=${token}`}
                    color={"black"}
                    target="_blank"
                  >
                    {user.video_keys.length}
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function UserEditModal({
  user,
  onClose,
  onUpdate,
}: {
  user: UserStat | null;
  onClose: UseModalProps["onClose"];
  onUpdate: (user: UserStat) => void;
}) {
  let [image, setImage] = useState<string | File | undefined>(user?.avatar);
  let [zoom, setZoom] = useState(1.0);
  let editor = useRef<AvatarEditor>(null);
  let nameInput = useRef<HTMLInputElement>(null);
  let token = useToken();

  async function updateInfo() {
    let alias = nameInput.current?.value;
    let avatar = editor.current?.getImageScaledToCanvas().toDataURL();
    if (alias || avatar) {
      await http.patch(
        "/platform/users",
        {
          id: user?.id,
          alias: alias,
          avatar: avatar,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
    }
    let retval = { alias, avatar };
    if (!alias) {
      delete retval.alias;
    }
    if (!avatar) {
      delete retval.avatar;
    }
    return retval;
  }

  useEffect(() => {
    if (!user) {
      setImage(undefined);
      setZoom(1.0);
    } else {
      if (nameInput.current) {
        nameInput.current.value = user.alias || "";
      }
    }
  }, [user]);

  if (user === null) {
    return null;
  }

  return (
    <Modal blockScrollOnMount={false} isOpen={user !== null} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit user: id {user?.id}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <VStack>
            <FormControl>
              <Input
                defaultValue={user.alias}
                ref={nameInput}
                placeholder="Name"
              />
              <FormErrorMessage>Error</FormErrorMessage>
            </FormControl>
            <FormControl>
              {image !== undefined ? (
                <VStack mt={4} alignItems={"center"}>
                  <AvatarEditor
                    ref={editor}
                    image={image}
                    width={256}
                    height={256}
                    border={50}
                    scale={zoom}
                  />
                  <Slider
                    aria-label="slider-ex-1"
                    min={1}
                    max={5}
                    step={0.1}
                    value={zoom}
                    onChange={setZoom}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb bg={"teal"} />
                  </Slider>
                  <Text>ZOOM</Text>
                </VStack>
              ) : user.avatar ? (
                <Image width={"256px"} height={"256px"} src={user.avatar} />
              ) : null}
              <Box mt={4}>
                <input
                  accept="image/*"
                  type="file"
                  onChange={(event) => {
                    let file = event.target.files?.[0];
                    if (file) {
                      setImage(file);
                    }
                  }}
                />
              </Box>

              <FormErrorMessage>Error</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            onClick={async () => {
              let info = await updateInfo();
              onUpdate({
                ...user,
                ...info,
              });
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default function Dashboard() {
  const { startDate, endDate } = useDate();
  if (startDate === undefined || endDate === undefined) {
    return null;
  }

  return (
    <Grid
      templateColumns="280px 1fr"
      overflow={"hidden"}
      sx={{
        "@media print": {
          display: "block",
        },
      }}
    >
      <GridItem
        h="100vh"
        overflow={"auto"}
        bg="gray.200"
        sx={{
          "@media print": {
            display: "none",
          },
        }}
      >
        <SearchFields initialStartDate={startDate} initialEndDate={endDate} />
      </GridItem>
      <GridItem
        h="100vh"
        overflow={"auto"}
        bg="gray.100"
        sx={{
          "@media print": {
            height: "initial",
          },
        }}
      >
        <DashboardContent startDate={startDate} endDate={endDate} />
      </GridItem>
    </Grid>
  );
}
