import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDate, useToken } from "~/utils";
import { UserStat } from "./dashboard";
import http from "~/utils/http";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

function useUserData(userId?: string) {
  const { startDate, endDate } = useDate();
  const token = useToken();
  const [user, setUser] = useState<UserStat | null | undefined>(null);
  useEffect(() => {
    let id = parseInt(userId || "");
    if (!id) {
      return;
    }
    if (startDate === undefined || endDate === undefined) {
      return;
    }
    const loadData = async (id: number) => {
      let params = new URLSearchParams();
      params.set("pageIndex", "0");
      params.set("pageSize", "9999");
      if (startDate) {
        params.set("startDate", startDate.toLocaleDateString("en-CA"));
      }
      if (endDate) {
        params.set("endDate", endDate.toLocaleDateString("en-CA"));
      }
      let res = await http.get<{ users: UserStat[] | null }>(
        "/platform/users/stats",
        {
          params: params,
          headers: {
            Authorization: token,
          },
        }
      );
      let data = res.data.users?.filter((u) => u.id === id)?.[0];
      setUser(data);
    };
    loadData(id);
  }, [userId, startDate, endDate]);

  return user;
}

function Pagination({
  page,
  setPage,
  numItems,
  itemsPerPage,
}: {
  page: number;
  setPage: (page: number) => void;
  numItems: number;
  itemsPerPage: number;
}) {
  const numPages = Math.ceil(numItems / itemsPerPage);
  return (
    <Flex justifyContent="center" m={4} alignItems="center">
      <Flex>
        <Tooltip label="First Page">
          <IconButton
            aria-label="first page"
            onClick={() => setPage(0)}
            isDisabled={page === 0}
            icon={<ArrowLeftIcon h={3} w={3} />}
            mr={4}
          />
        </Tooltip>
        <Tooltip label="Previous Page">
          <IconButton
            aria-label="previous page"
            onClick={() => setPage(page - 1)}
            isDisabled={page === 0}
            icon={<ChevronLeftIcon h={6} w={6} />}
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center">
        <Text flexShrink="0" mx={8}>
          Page{" "}
          <Text fontWeight="bold" as="span">
            {page + 1}
          </Text>{" "}
          of{" "}
          <Text fontWeight="bold" as="span">
            {numPages}
          </Text>
        </Text>
        <Text flexShrink="0">Go to page:</Text>{" "}
        <NumberInput
          ml={2}
          mr={8}
          w={28}
          min={1}
          max={numPages}
          onChange={(_, value) => {
            if (value > numPages) {
              return;
            }
            const page = value ? value - 1 : 0;
            setPage(page);
          }}
          defaultValue={page + 1}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Flex>

      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            aria-label="next page"
            onClick={() => setPage(page + 1)}
            isDisabled={page === numPages - 1}
            icon={<ChevronRightIcon h={6} w={6} />}
          />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton
            aria-label="last page"
            onClick={() => setPage(numPages - 1)}
            isDisabled={page === numPages - 1}
            icon={<ArrowRightIcon h={3} w={3} />}
            ml={4}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
}

interface VideoInfo {
  id: number;
  startTime: number;
  endTime: number;
  content: {
    id: number;
    content: string;
  };
  thumbnail_url: string;
  status: string;
  processedVideoUrl: string | null;
  videoUrl: string;
}

function TableItem({ videoKey }: { videoKey: string }) {
  const [info, setInfo] = useState<VideoInfo | null>(null);
  const token = useToken();
  useEffect(() => {
    async function loadInfo() {
      let res = await http.get<VideoInfo>("/platform/videos", {
        headers: {
          Authorization: token,
        },
        params: {
          videoKey: videoKey,
        },
      });
      setInfo(res.data);
    }
    loadInfo();
  }, [videoKey]);
  return (
    <Tr>
      <Td>
        <Text fontSize={"sm"}>{videoKey}</Text>
      </Td>
      <Td>{info?.content.content}</Td>
      <Td>{info ? `${info.startTime / 1000} - ${info.endTime / 1000}` : ""}</Td>
      <Td>
        {info ? (
          <Image
            objectFit="cover"
            width={16}
            height={16}
            src={info.thumbnail_url}
          />
        ) : (
          <Spinner />
        )}
      </Td>
      <Td>
        {!info ? (
          ""
        ) : (
          <VStack>
            {info.processedVideoUrl ? (
              <Button
                as="a"
                href={info.processedVideoUrl}
                target="_blank"
                colorScheme="blue"
                variant="link"
                size={"sm"}
              >
                剪辑后视频
              </Button>
            ) : (
              "剪辑视频处理中"
            )}

            <Button
              as="a"
              href={info.videoUrl}
              target="_blank"
              colorScheme="blue"
              variant="link"
              size={"sm"}
            >
              原视频
            </Button>
          </VStack>
        )}
      </Td>
    </Tr>
  );
}

export default function UserVideos() {
  const { userId } = useParams();
  const { startDate, endDate } = useDate();
  const user = useUserData(userId);
  const [page, setPage] = useState(0);
  if (user === null) {
    return (
      <Container textAlign={"center"} my={8}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Container>
    );
  } else if (user === undefined) {
    return (
      <Container textAlign={"center"} my={8}>
        No data found;
      </Container>
    );
  }
  const itemsPerPage = 10;

  const tableData = user.video_keys.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );
  return (
    <Container maxW="6xl" my={8}>
      <VStack>
        <Avatar src={user.avatar} name={user.alias} />
        <Heading fontSize={"lg"}>{user.alias || `<id:${user.id}>`}</Heading>
        <Divider />
      </VStack>
      <Table my={4} bg={"gray.100"} variant="striped" colorScheme="teal">
        <TableCaption>
          <strong>{user.video_keys.length}</strong> videos uploaded from{" "}
          {startDate ? startDate.toLocaleDateString("en-CA") : "the beginning"}{" "}
          to {endDate ? endDate.toLocaleDateString("en-CA") : "today"}
        </TableCaption>
        <Thead>
          <Tr>
            <Th>Key</Th>
            <Th>Content</Th>
            <Th>Time</Th>
            <Th>Preview</Th>
            <Th textAlign="center">Download</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((videoKey) => (
            <TableItem key={videoKey} videoKey={videoKey} />
          ))}
        </Tbody>
      </Table>
      <Pagination
        page={page}
        setPage={setPage}
        numItems={user.video_keys.length}
        itemsPerPage={itemsPerPage}
      />
    </Container>
  );
}
