import { useEffect, useState } from "react";
import http from "./http";
import { useLocation, useNavigate } from "react-router-dom";

export interface User {
  name: string;
  email: string;
  avatar?: string;
}

function getToken() {
  const urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get("token");
  if (token) {
    sessionStorage.setItem("token", token);
  }
  let sessionData = sessionStorage.getItem("token");
  return sessionData;
}

export function useToken() {
  return "nexEIAL2IA1HCgNozErr0VoozUlVSMFEDIrVaQDq6uHk";
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(getToken());
  useEffect(() => {
    let sessionData = getToken();
    if (!sessionData) {
      navigate("/signin");
      return;
    }
    setToken(sessionData);
  }, [navigate]);
  return token;
}

function parseDate(str: string | null | undefined, defaultDate: Date) {
  if (str === "null") {
    return null;
  } else if (str) {
    let ts = Date.parse(str);
    if (ts) {
      return new Date(ts);
    }
  }
  return defaultDate;
}

export function useDate() {
  const today = new Date();
  let location = useLocation();
  let navigate = useNavigate();
  const [startDate, setStartDate] = useState<Date | null | undefined>(
    undefined
  );
  const [endDate, setEndDate] = useState<Date | null | undefined>(undefined);
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    let startStr = urlParams.get("start-date");
    let endStr = urlParams.get("end-date");
    if (startStr) {
      let d = parseDate(startStr, today);
      setStartDate(d);
    } else {
      urlParams.set("start-date", today.toLocaleDateString("en-CA"));
    }
    if (endStr) {
      let d = parseDate(endStr, today);
      setEndDate(d);
    } else {
      urlParams.set("end-date", today.toLocaleDateString("en-CA"));
    }
    if (!startStr || !endStr) {
      navigate("/?" + urlParams.toString());
    }
  }, [location]);

  return {
    startDate,
    endDate,
  };
}
