import axios from "axios";

export const getBaseUrl = () => {
  if (process.env.REACT_APP_URL) {
    return process.env.REACT_APP_URL;
  }
  let url;
  switch (process.env.NODE_ENV) {
    case "production":
      url = "https://signnet.sath.tech";
      break;
    case "development":
    default:
      url = "http://localhost:9001";
  }

  return url;
};

export default axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
});
