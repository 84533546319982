import Dashboard from "./console/dashboard";
import ConsoleLayout from "./console/layout";
import UserVideos from "./console/user_videos";
import ErrorPage from "./error-page";
import Layout from "./home/layout";
import SignIn from "./home/signin";

const routes = [
  {
    element: <Layout />,
    children: [
      {
        path: "/signin",
        element: <SignIn />,
      },
    ],
    errorElement: <ErrorPage />,
  },
  {
    element: <ConsoleLayout />,
    children: [
      {
        path: "/",
        index: true,
        element: <Dashboard />,
      },
      {
        path: "/users/:userId",
        element: <UserVideos />,
      },
    ],
  },
];
export default routes;
