import { Button, ButtonProps } from "@chakra-ui/react";

export interface FormButtonProps extends ButtonProps {}

export default function FormSubmitButton({
  isLoading,
  loadingText,
  type,
  ...rest
}: FormButtonProps) {
  const isSubmitting = false;
  return (
    <Button
      type="submit"
      isLoading={isSubmitting}
      loadingText={loadingText || "Submitting"}
      {...rest}
    />
  );
}
