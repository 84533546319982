import { Outlet } from "react-router-dom";
import { useToken } from "~/utils";

export default function Layout() {
  const token = useToken();
  if (token === null) {
    return null;
  }
  return <Outlet />;
}
