import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  InputRightElement,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Link, useNavigate } from "react-router-dom";
import * as z from "zod";
import FormInput from "~/ui/Form/FormInput";
import FormSubmitButton from "~/ui/Form/FormSubmitButton";
import http from "~/utils/http";

const schema = z.object({
  token: z.string().min(1, "Please input your token"),
});

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const navigate = useNavigate();
  return (
    <Flex bg={useColorModeValue("gray.50", "gray.800")}>
      <Stack spacing={8} mx={"auto"} width="440px" maxW={"lg"} py={20} px={6}>
        <Stack align={"center"}>
          <Heading>Login to console</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Form
            onSubmit={handleSubmit(async (data) => {
              sessionStorage.setItem("token", data.token);
              navigate("/");
            })}
          >
            <Stack spacing={4}>
              <FormInput
                placeholder="Please input your token"
                as={Textarea}
                {...register("token")}
                error={errors.token?.message}
              />

              <Stack spacing={10}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                ></Stack>
                <FormSubmitButton type="submit" colorScheme="teal">
                  Login
                </FormSubmitButton>
              </Stack>
            </Stack>
          </Form>
        </Box>
      </Stack>
    </Flex>
  );
}
